import {
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { IPublicClientApplication } from '@azure/msal-browser';
import {
  FluentProvider,
  teamsLightTheme,
} from '@fluentui/react-components';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { UserProvider } from 'context';

import { Login } from 'components/SSO';
import Admin from 'components/Admin';
import Signup from 'components/Signup';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([{
  path: "/signup",
  element: <Signup />,
},{
  path: "*",
  element: <Admin />,
}]);

const App = ({ pca }: { pca: IPublicClientApplication }) => {
  return (
    <MsalProvider instance={pca}>
      <AuthenticatedTemplate>
        <UserProvider>
          <FluentProvider theme={teamsLightTheme}>
            <RouterProvider router={router} />
          </FluentProvider>
        </UserProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default App;
