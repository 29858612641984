import logoBlackText from 'assets/images/logo-black-text.webp';

const Contact = () => {
  return (
    <div className="mt-20 flex flex-col items-center gap-1.5">
      <a
        href="https://www.virtuosis.ai"
        rel="noreferrer"
        target="_blank"
      >
        <img
          width={100}
          height={100}
          src={logoBlackText}
          alt="Virtuosis logo"
        />
      </a>
      <a
        className="text-teamsPurple"
        href="https://www.virtuosis.ai/contact"
        rel="noreferrer"
        target="_blank"
      >
        Contact us
      </a>
    </div>
  );
};

export default Contact;
