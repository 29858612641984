import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import { useMsal } from '@azure/msal-react';
import {
  SilentRequest,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

import { msGraphScopes } from 'sso';

import {
  getUserEmail,
  getUserImage,
  getOrganisation
} from './utils';

export interface User {
  id: string;
  name: string;
  email: string;
  image: string | null;
  orgId: string;
  orgName: string;
};

const initUser: User = {
  id: '',
  name: '',
  email: '',
  image: null,
  orgId: '',
  orgName: '',
};

interface UserContextTypes {
  user: User;
  getAccessToken: (scopes: string[]) => Promise<string>;
};

const UserContext = createContext<UserContextTypes>({
  user: initUser,
  getAccessToken() { return new Promise(() => {})},
});

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [user, setUser] = useState<User>(initUser);

  const { instance } = useMsal();

  const getSSOToken = useCallback(async (request: SilentRequest) => {
    try {
      return await instance.acquireTokenSilent(request);
    } catch (e: any) {
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(request);
      }
    }
  }, [instance]);

  const getAccessToken = useCallback(async (scopes: string[]) => {
    const { accessToken } = await getSSOToken({ scopes }) || {};

    if (!accessToken) {
      throw new Error('No accessToken returned by getSSOToken');
    }

    return accessToken;
  }, [getSSOToken]);

  const setUserInfo = useCallback(async () => {
    const { account, accessToken } = await getSSOToken({ scopes: msGraphScopes }) || {};

    if (!accessToken) {
      throw new Error('No accessToken returned by getSSOToken');
    }

    const { name, tenantId, localAccountId } = account || {};

    if (!name || !tenantId || !localAccountId) {
      throw new Error('Could not parse name, tenantId, or localAccountId');
    }

    const email = await getUserEmail(localAccountId, accessToken);

    const image = await getUserImage(localAccountId, accessToken);

    const { displayName } = await getOrganisation(tenantId, accessToken);

    setUser({
      name,
      email,
      image,
      id: localAccountId,
      orgId: tenantId,
      orgName: displayName || '',
    });
  }, [getSSOToken]);

  useEffect(() => { setUserInfo(); }, [setUserInfo]);

  return (
    <UserContext.Provider
      value={{
        user,
        getAccessToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
