import * as Sentry from '@sentry/react';
import axios from 'axios';

export const getUserEmail = async (userId: string, accessToken: string) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/users/${userId}?$select=mail`,
      headers: { Authorization: accessToken },
    });

    const { mail } = data || {};

    return mail as string || '';
  } catch (err) {
    Sentry.captureException(err);

    return '';
  }
};

export const getUserImage = async (userId: string, accessToken: string) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/users/${userId}/photos/48x48/$value`,
      headers: {
        Authorization: accessToken,
        'Content-Type': 'image/jpg',
      },
      responseType: 'blob',
    });

    if (data?.size && data?.type === 'image/png') {
      return URL.createObjectURL(data);
    }

    return null;
  } catch (err) {
    Sentry.captureException(err);

    return null;
  }
};

export const getOrganisation = async (tenantId: string, accessToken: string) => {
  try {
    const { data } = await axios({
      method: 'GET',
      url: `https://graph.microsoft.com/v1.0/organization/${tenantId}`,
      headers: { Authorization: accessToken },
    });

    return data;
  } catch (err) {
    Sentry.captureException(err);

    return {};
  }
};
