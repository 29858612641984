import { useCallback } from 'react';
import axios from 'axios';

import { useUser } from 'context';
import { virtuosisScopes } from 'sso';

export interface SubscribeEvent {
  planId: string;
  quantity: number;
  subscription: {
    id: string;
    name: string;
    offerId: string;
    created: string;
    autoRenew: boolean;
    publisherId: string;
    saasSubscriptionStatus: string;
    term: {
      endDate: string;
      termUnit: string;
      startDate: string;
    };
    beneficiary: {
      objectId: string;
      tenantId: string;
    };
  };
};

const getMarketplaceEndpoint = (endpoint: string) => (
  `https://marketplaceapi.microsoft.com/api/saas/subscriptions${endpoint}?api-version=2018-08-31`
);

const resolveMarketplaceToken = async (
  marketplaceToken: string,
  publisherToken: string,
) => {
  const { data, status } = await axios({
    method: 'POST',
    url: getMarketplaceEndpoint('/resolve'),
    headers: {
      Authorization: `Bearer ${publisherToken}`,
      'Content-Type': 'application/json',
      'X-Ms-Marketplace-Token': marketplaceToken.replace(/\s/g, '+'),
    },
  });

  return {
    status,
    data: data as SubscribeEvent,
  };
};

const activateMarketplaceSubscription = async (
  subscribeEvent: SubscribeEvent,
  publisherToken: string,
) => {
  const { status } = await axios({
    method: 'POST',
    url: getMarketplaceEndpoint(`/${subscribeEvent.subscription.id}/activate`),
    data: {
      planId: subscribeEvent.planId,
      quantity: subscribeEvent.quantity,
    },
    headers: {
      Authorization: `Bearer ${publisherToken}`,
      'Content-Type': 'application/json',
    },
  });

  return { status };
};

const callActivateAPI = async (
  publisherToken: string,
  se: SubscribeEvent,
  organisationName: string,  
) => {
  const { status } = await axios({
    method: 'POST',
    url: 'https://virtuosis-client-prod.azure-api.net/subscription/v1.0/activate',
    data: {
      organisationName,
      id: se.subscription.id,
      planId: se.planId,
      offerId: se.subscription.offerId,
      created: se.subscription.created,
      quantity: se.quantity,
      autoRenew: se.subscription.autoRenew,
      saasSubscriptionStatus: se.subscription.saasSubscriptionStatus,
      term: {
        termUnit: se.subscription.term.termUnit,
        startDate: se.subscription.term.startDate,
        endDate: se.subscription.term.endDate,
      },
      beneficiary: {
        objectId: se.subscription.beneficiary.objectId,
        tenantId: se.subscription.beneficiary.tenantId,
      },
    },
    headers: {
      Authorization: publisherToken,
      'Content-Type': 'application/json',
    },
  });

  return {
    status,
    data: null,
  };
};

const getPublisherToken = async (accessToken: string) => {
  const { data } = await axios({
    method: 'GET',
    url: 'https://virtuosis-client-prod.azure-api.net/subscription/v1.0/publishertoken',
    headers: { Authorization: accessToken },
  });

  const { access_token } = data || {};

  return access_token as string || '';
};

const useSubscription = () => {
  const { getAccessToken } = useUser();

  const resolve = useCallback(async (marketplaceToken: string) => {
    const accessToken = await getAccessToken(virtuosisScopes);

    if (!marketplaceToken) {
      throw new Error('no marketplaceToken provided');
    }

    return resolveMarketplaceToken(
      marketplaceToken,
      await getPublisherToken(accessToken),
    );
  }, [getAccessToken]);

  const activate = useCallback(async (
    subscribeEvent: SubscribeEvent,
    organisationName: string,
  ) => {
    const accessToken = await getAccessToken(virtuosisScopes);

    const { status } = await callActivateAPI(accessToken, subscribeEvent, organisationName);

    if (status !== 200) {
      throw new Error('Could not activate subscription in Virtuosis backend');
    }

    return activateMarketplaceSubscription(
      subscribeEvent,
      await getPublisherToken(accessToken),
    );
  }, [getAccessToken]);

  return {
    resolve,
    activate,
  };
};

export default useSubscription;
