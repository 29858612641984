import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';

import { loginScopes } from 'sso';

const Login = () => {
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      if (!instance.getActiveAccount()) {
        await instance.loginRedirect({
          scopes: loginScopes,
          prompt: 'select_account',
        });
      }
    })()
  }, [instance]);

  return <></>;
};

export default Login;
