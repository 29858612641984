import { LogLevel, Configuration } from '@azure/msal-browser';

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const config: Configuration = {
  auth: {
    clientId: '8184def4-9134-4920-bf82-05e31fa44469',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {	
          return;	
        }
        switch (level) {	
          case LogLevel.Error:	
            console.error(message);	
            return;	
          case LogLevel.Info:	
            console.info(message);	
            return;	
          case LogLevel.Verbose:	
            console.debug(message);	
            return;	
          case LogLevel.Warning:	
            console.warn(message);	
            return;	
          default:
            return;
        }
      }
    }
  }
};

export const msGraphScopes = ['User.Read'];

export const virtuosisScopes = [
  'api://teams.virtuosis.ai/8184def4-9134-4920-bf82-05e31fa44469/App.Signup',
];

export const loginScopes = [...msGraphScopes, ...virtuosisScopes];
