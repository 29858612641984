import Contact from "components/Contact";

const Admin = () => {
  return (
    <div className="h-screen bg-background flex items-center justify-center">
      <div className="-mt-20">
        <Contact />
      </div>
    </div>
  );
};

export default Admin;
