import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Open16Regular } from '@fluentui/react-icons';
import { Field } from '@fluentui/react-components/unstable';
import { Avatar, Spinner, ProgressBar } from '@fluentui/react-components';

import { User, useUser } from 'context';
import Contact from 'components/Contact';
import { LogoutButton } from 'components/SSO';
import { Button } from 'components/Interactives';
import { SubscribeEvent, useSubscription } from 'hooks';

import Summary from './Summary';

const WIDTH = 680;

const redirectUri = window.location.hostname === 'localhost'
  ? `http://localhost:${window.location.port}/signup`
  : 'https://admin.virtuosis.ai/signup';

type State = 'ERROR' | 'NOTOKEN' | 'CONSENT' | 'ACTIVATE' | 'ACTIVATED';

const getErrorContext = (user: User, subscriptionId: string | undefined) => {
  const errorContext = [
    `User Name: ${user.name}`,
    `User Email: ${user.email}`,
    `Organisation Name: ${user.orgName}`,
    `Subscription Id: ${subscriptionId  || 'unkonwn'}`,
  ];

  return ` | ${errorContext.join(', ')}`;
};

export const Signup = () => {
  document.title = 'Subscription | Virtuosis';

  const [state, setState] = useState<State | null>(null);

  const [isActivating, setIsActivating] = useState(false);

  const [purchaserId, setPurchaserId] = useState<string | null>(null);

  const [subscribeEvent, setSubscribeEvent] = useState<SubscribeEvent | null>(null);

  const { user } = useUser();

  const [searchParams] = useSearchParams();

  const { resolve, activate } = useSubscription();

  const error = searchParams.get('error');

  const adminConsent = searchParams.get('admin_consent');

  const errorDescription = searchParams.get('error_description');

  const marketplaceToken = searchParams.get('token') || searchParams.get('state');

  useEffect(() => {
    (async () => {
      let subscriptionId;

      try {
        if (!marketplaceToken) {
          setState('NOTOKEN');
          return;
        }

        const { data, status } = await resolve(marketplaceToken);

        if (status !== 200) {
          throw new Error('resolve returned non-200 response');
        }

        subscriptionId = data.subscription.id;

        setSubscribeEvent(data);

        setPurchaserId(data.subscription.beneficiary.objectId)

        if (error === "invalid_request") {
          throw new Error(errorDescription || error);
        }

        // TODO: Add token check.
        if (!adminConsent || (adminConsent && error)) {
          setState('CONSENT');
          return;
        }

        const isPending = data.subscription.saasSubscriptionStatus === 'PendingFulfillmentStart';

        setState(isPending ? 'ACTIVATE' : 'ACTIVATED');
      } catch (err) {
        if (err instanceof Error) {
          err.message += getErrorContext(user, subscriptionId);
        }

        Sentry.captureException(err);

        setState('ERROR');
      }
    })()
  }, [
    user,
    error,
    resolve,
    adminConsent,
    errorDescription,
    marketplaceToken,
  ]);

  if (!state || !user.id) {
    return (
      <div className="h-screen bg-background flex justify-center">
        <Spinner />
      </div>
    );
  }

  if (state === 'ERROR' || state === 'NOTOKEN' || !purchaserId || !subscribeEvent) {
    return (
      <div className="pt-20 bg-background flex gap-5 flex-col items-center">
        <span>
          {state === 'NOTOKEN' ? (
            <>Error: Missing Microsoft AppSource token.</>
          ) : (
            <>An unexpected error occured.</>
          )}
        </span>
        <span>
          Please try to reaccess your Virtuosis publisher link from your Microsoft AppSource subscription.
        </span>
        <span>
          Please contact us if the issue is persisting - we are happy to help!
        </span>
        <Contact />
      </div>
    );
  }

  return (
    <div className="pt-10 bg-background flex flex-col items-center gap-10">
      <div
        className="flex gap-3 justify-end items-center"
        style={{ width: WIDTH }}
      >
        <Avatar
          aria-label={user.name}
          name={user.name}
          image={{ src: user.image || undefined }}
        />
        <LogoutButton />
      </div>
      <span
        className="text-2xl font-semibold"
        style={{ width: WIDTH }}
      >
        Virtuosis Subscription
      </span>
      <Summary
        user={user}
        width={WIDTH}
        purchaserId={purchaserId}
        subscribeEvent={subscribeEvent}  
      />
      <Field
        validationMessage={
          state === 'CONSENT' ? 'Admin consent required...'
          : state === 'ACTIVATE' ? 'Activation required...'
          : 'Sign up completed'
        }
        validationState="none"
        style={{ width: WIDTH }}
      >
        <ProgressBar
          value={
            state === 'CONSENT' ? 0.35
            : state === 'ACTIVATE' ? 0.82
            : 1
          }
        />
      </Field>
      <div className="h-16">
        {
          state === 'CONSENT' ? (
            <Button
              icon={<Open16Regular />}
              title="Admin Consent"
              onClick={() => {
                if (!marketplaceToken || !user.orgId) {
                  return;
                }

                const params = new URLSearchParams({
                  redirect_uri: redirectUri,
                  state: marketplaceToken,
                  scope: 'https://graph.microsoft.com/.default',
                  client_id: '8184def4-9134-4920-bf82-05e31fa44469',
                }).toString();

                window.location.assign(
                  `https://login.microsoftonline.com/${user.orgId}/v2.0/adminconsent?${params}`
                );
              }}
            />
          )
          : state === 'ACTIVATE' ? (
              <Button
                title="Activate Subscription"
                isLoading={isActivating}
                onClick={async () => {
                  if (!subscribeEvent) {
                    return;
                  }

                  setIsActivating(true);

                  try {
                    const { status } = await activate(subscribeEvent, user.orgName);

                    if (status !== 200) {
                      throw new Error('Unable to activate subscription');
                    }

                    setSubscribeEvent({
                      ...subscribeEvent,
                      subscription: {
                        ...subscribeEvent.subscription,
                        saasSubscriptionStatus: 'Subscribed',
                      }
                    });

                    setState('ACTIVATED');
                  } catch (err) {
                    if (err instanceof Error) {
                      const subscriptionId = subscribeEvent.subscription.id;
                      err.message += getErrorContext(user, subscriptionId);
                    }

                    Sentry.captureException(err);

                    setState('ERROR');
                  }

                  setIsActivating(false);
                }}
              />
          )
          : state === 'ACTIVATED' ? (
            <div className="flex flex-col gap-3 items-center">
              <div className="inline-flex gap-1 items-center">
                <span>
                  Please visit
                </span>
                <a
                  className="flex gap-0.5 items-center text-teamsPurple font-semibold"
                  href="https://aka.ms/teamsapplicensemanagement"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    Teams subscriptions manager
                  </span>
                  <Open16Regular />
                </a>
                <span>
                  to manage your subscription and licences.
                </span>
              </div>
              <div>
                Please allow up to 10 minutes for your subscription to become available.
              </div>
            </div>
          )
          : <></>
        }
      </div>
      <Contact />
    </div>
  );
};

export default Signup;
