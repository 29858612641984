import {
  Avatar,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableCellLayout,
} from '@fluentui/react-components';
import {
  Timer24Regular,
  Status24Regular,
  Building24Regular,
  SlideText24Regular,
  PersonAdd24Regular,
  PersonMoney24Regular,
  DocumentText24Regular,
  PersonAvailable24Regular,
  ArrowSyncCircle24Regular,
} from '@fluentui/react-icons';

import { User } from 'context';
import { SubscribeEvent } from 'hooks';

const getItems = (
  user: User,
  purchaserId: string,
  subscribeEvent: SubscribeEvent,
) => ([
  {
    key: {
      label: 'Activator',
      icon: <PersonAvailable24Regular />,
    },
    value: {
      label: user.name,
      media: (
        <Avatar
          aria-label={user.name}
          name={user.name}
          image={{ src: user.image || undefined }}
        />
      ),
    },
  },{
    key: { label: 'Purchaser Id', icon: <PersonMoney24Regular /> },
    value: { label: purchaserId },
  },{
    key: { label: 'Organisation', icon: <Building24Regular /> },
    value: { label: user.orgName },
  },{
    key: { label: 'Subscription Id', icon: <SlideText24Regular /> },
    value: { label: subscribeEvent.subscription.id },
  },{
    key: { label: 'Subscription Name', icon: <DocumentText24Regular /> },
    value: { label: subscribeEvent.subscription.name },
  },{
    key: { label: 'Subscription Status', icon: <Status24Regular /> },
    value: { label: subscribeEvent.subscription.saasSubscriptionStatus },
  },{
    key: { label: 'Seat Count', icon: <PersonAdd24Regular /> },
    value: { label: subscribeEvent.quantity },
  },{
    key: { label: 'Term Duration', icon: <Timer24Regular /> },
    value: {
      label: subscribeEvent.subscription.term.termUnit === 'P1M' ? 'Monthly'
        : subscribeEvent.subscription.term.termUnit === 'P1Y' ? 'One-year'
        : subscribeEvent.subscription.term.termUnit === 'P3Y' ? 'Three-years'
        : '',
    },
  },{
    key: { label: 'Auto Renew Enabled', icon: <ArrowSyncCircle24Regular /> },
    value: {
      label: String(subscribeEvent.subscription.autoRenew).toLowerCase() === 'true' ? 'Yes' : 'No'
    },
  },
]);

const Summary = ({
  width,
  user,
  purchaserId,
  subscribeEvent,
}:{
  width: number;
  user: User;
  purchaserId: string;
  subscribeEvent: SubscribeEvent;
}) => {
  return (
    <Table
      size="small"
      aria-label="Table with small size"
      style={{ width }}
    >
    <TableBody>
      {getItems(user, purchaserId, subscribeEvent).map((item) => (
        <TableRow key={item.key.label}>
          <TableCell>
            <TableCellLayout media={item.key.icon}>
              {item.key.label}
            </TableCellLayout>
          </TableCell>
          <TableCell>
            <TableCellLayout media={item.value.media}>
              {item.value.label}
            </TableCellLayout>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  );
};

export default Summary;
